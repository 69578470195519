<template>
  <ion-modal
    class="modal-big modal-with-layout"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="false"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" class="layout" @dismiss-modal="dismissModal">
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <i class="icon pl-2 ti-search position-absolute" />
          <ion-input class="c-input one-input" placeholder="Search your characters" v-model="searchValue" />
        </div>
      </div>

      <div class="main mt-1 d-flex flex-column overflow-auto">
        <ChLoading size="md" v-if="isCharLoading" class="spinner m-auto" />

        <div v-else-if="characters.length" class="selectable-characters mb-3 d-flex align-items-center">
          <Grid :scrollSm="false">
            <CharacterCard
              v-for="(character, index) of characters"
              :key="index"
              class="grid-item"
              :character="character"
              is-selectable
              :is-selected="isCharacterSelected(character)"
              @select="select(character, $event)"
            />
          </Grid>

          <ion-button v-if="hasNextPage" class="clickable" fill="clear" color="dark" @click="requestLoadMore"
            >Load More →</ion-button
          >
        </div>
        <div v-else class="no-data m-auto">No characters</div>
      </div>
      <div class="d-flex mt-1">
        <ion-custom-button
          :disabled="isSaving"
          :loading="isSaving"
          color="primary"
          class="mt-3 mr-2 action-btn"
          @click="save"
          >Save</ion-custom-button
        >
        <ion-custom-button :disabled="isSaving" color="medium" class="mt-3 action-btn" @click="dismissModal"
          >Cancel</ion-custom-button
        >
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts" setup>
import { Character, UserProfile } from '@/shared/types/static-types';
import { toast } from '@/shared/native/toast';
import { profileStore } from '@/shared/pinia-store/profile';
import Grid from '@/shared/components/storage/Grid.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { getUserCharacters } from '@/shared/actions/characters';
import { getNextPage } from '@/shared/helpers/pagination';

const { loadMoreProfileCharacters, updateUserProfile, isSaving } = profileStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
  },
  modalTitle: {
    type: String,
    default: 'Select featured characters',
  },
  selectedChars: {
    type: Array as PropType<Character[]>,
    default: () => [],
  },
});

const searchValue = ref('');
const isCharLoading = ref(false);
const characters = ref<Character[]>([]);
const userCharsPaging = ref<Paging>();

const selectedChars = toRef(props, 'selectedChars');

const sortableOptions = ref({
  handle: '.reorder-handle',
  animation: 150,
});

const emits = defineEmits(['dismissModal', 'save']);

const dismissModal = () => {
  userFeaturedCharacters.value = cloneDeep(selectedChars.value || []);
  emits('dismissModal');
};

const isCharacterSelected = (character: Character) => {
  return userFeaturedCharactersIds.value.includes(character?.id!);
};

const fetchCharacters = async () => {
  isCharLoading.value = true;

  const { user } = authStore();
  const { results, ...paging } = !isEmpty(searchValue.value)
    ? await getUserCharacters(user.value.id, 1, { page_size: 40, search: searchValue.value })
    : await getUserCharacters(user.value.id, 1, { page_size: 40 });
  characters.value = results;
  userCharsPaging.value = paging;

  isCharLoading.value = false;
};

const hasNextPage = computed(() => {
  return !!userCharsPaging.value?.next;
});

const requestLoadMore = async () => {
  if (!userCharsPaging.value?.next) {
    (ev?.target as any).complete();
  } else {
    isCharLoading.value = true;

    const { results, ...paging } = await getNextPage(userCharsPaging.value!);
    characters.value = characters.value.concat(results);
    userCharsPaging.value = paging;

    isCharLoading.value = false;
  }
};

const select = (character: Character, isSelected: boolean) => {
  if (isSelected) {
    userFeaturedCharacters.value.push(character);
    userFeaturedCharacters.value = [...new Set(userFeaturedCharacters.value)];
  }

  if (!isSelected) {
    userFeaturedCharacters.value = userFeaturedCharacters.value.filter(({ id }) => id !== character.id);
  }
};

const userFeaturedCharactersIds = ref<string[]>([]);
const userFeaturedCharacters = ref<Character[]>([]);

const save = () => {
  emits('save', userFeaturedCharacters.value);
  dismissModal();
};

watch(selectedChars, (val) => (userFeaturedCharacters.value = cloneDeep(val || [])), { deep: true });

watch(userFeaturedCharacters, (val) => (userFeaturedCharactersIds.value = (val || []).map(({ id }) => id!)), {
  deep: true,
});

const searchTextChanged = debounce(fetchCharacters, 500);

watch(searchValue, () => searchTextChanged());

onMounted(() => {
  fetchCharacters();
  userFeaturedCharacters.value = cloneDeep(selectedChars.value || []);
});
</script>

<style lang="sass" scoped>
.placeholder
  width: 60px !important
  min-width: 60px !important
  pointer-events: none
  height: 60px
  background: rgba(234, 236, 246, 1)
  border: 2px solid rgba(167, 170, 190, 1)
  border-radius: 6px
  display: flex
  align-items: center
  justify-content: center

.icon
  z-index: 22
ion-radio
  width: 30px
  height: 30px


ion-radio::part(container)
  border-radius: 8px
  border: 2px solid #ddd


ion-radio::part(mark)
  background: none
  transition: none
  transform: none
  border-radius: 0


ion-radio.radio-checked::part(container)
  background: #6815ec
  border-color: transparent


ion-radio.radio-checked::part(mark)
  width: 6px
  height: 10px

  border-width: 0px 2px 2px 0px
  border-style: solid
  border-color: #fff

  transform: rotate(45deg)
.color
  &:not(.selected)
    cursor: pointer
.modal-big
  font-size: 14px
.main
  height: calc(100% - 125px - var(--safe-ios-margin)* 1.333)
  overflow-x: hidden !important
.color
  border-radius: 20px
  height: 160px
  width: 40px
  &.selected
    border: 3px solid #999
.title
  font-weight: 700
  font-size: 16px !important
.modal-big
  --width: 616px
  --height: 80%
.profile-picture
  width: 160px
  height: 160px
  border-radius: 16px
  .round-button
    bottom: 0
    right: 10px
    --padding-start: 0
    --padding-end: 0
    width: 40px
    height: 40px
  img
    width: 100%
    height: 100%
    object-fit: cover
    width: 160px
    height: 160px
    border-radius: 16px

.social-media-button
  min-width: 33px
  width: 33px
  height: 33px
  color: #FFF
  --padding-start: 0
  --padding-end: 0
  visibility: visible !important

@media(max-width: 768px)
  .modal-big
    --width: 100%
    --height: 100%
.c-input
  ::v-deep
    input
      padding-left: 30px !important

@media(max-width: 615px)
  .first-row
    flex-direction: column
    .second-section
      margin-left: 0 !important
      margin-top: 1rem
    .first-section
      align-items: center !important
      .title
        align-self: flex-start
.action-btn
  width: 90px !important
  margin-top: 0 !important
  ::v-deep
    *
      text-transform: unset !important
    .button
      height: 40px !important
</style>
