<template>
  <ion-page class="page scrollable" id="page-editor">
    <div class="title py-3">{{ titleAction }} a Post</div>

    <div class="mt-2">
      <span class="d-flex justify-content-start align-items-center">
        <ProfileRing
          :image="lastPostAsProfileRing"
          :borderWidth="50"
          :ringTop="-26"
          :ringLeft="-5"
          class="position-absolute"
        />
        <img
          loading="lazy"
          v-image
          class="one-character clickable-item-hov no-select"
          :src="lastPostAsProfileImage"
          @click="openPostAsList"
        />
        <div class="ml-2 clickable-item-hov" @click="openPostAsList">
          <span class="text-black posting-as no-select">{{ isPostAsUser ? username : lastPostAsName }}</span>
          <i class="ti-angle-down open-more text-black" />
        </div>
      </span>
    </div>

    <div class="mt-3">
      <ion-input v-model="blabbing.title" class="c-input mt-1" placeholder="Title" maxlength="250" />
    </div>

    <div class="w-100 mt-3 c-tiny-mce">
      <Editor
        :key="rerenderEditorKey"
        ref="post"
        v-model="blabbing.description"
        :api-key="`${tinyMCEKey}`"
        :init="{
          height: 500,
          relative_urls: false,
          menu: {
            insert: {
              title: 'Insert',
              items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
            },
          },
          menubar: 'edit view insert format tools table help',
          images_upload_url: imageUploadUrl,
          images_upload_handler: image_uploader,

          plugins:
            'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
          toolbar:
            'undo redo | bold italic backcolor | image | blocks |  fontfamily | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
          contextmenu: false,
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: 'bold italic underline strikethrough link',
        }"
        placeholder="Upload art, write stories, roleplay as your character, and more! Get creative or get chatty!"
      />
    </div>

    <div class="mb-1 mt-3 d-flex align-items-center">
      <div @click="() => (changeColor = true)">
        <ion-checkbox
          :disabled="blabbing.privacy === 'only_me' ? false : !(user.is_nsfw || user.show_nsfw)"
          v-model="blabbing.is_nsfw"
          class="mr-1"
        ></ion-checkbox>
      </div>
      <ion-label
        :style="blabbing.privacy === 'only_me' ? false : !(user.is_nsfw || user.show_nsfw) ? 'color: grey' : ''"
        class="label no-select"
        >Mark post as Mature
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
          class="no-select"
          >(see content guidelines)</a
        ></ion-label
      >
    </div>
    <div
      v-if="blabbing.privacy === 'only_me' ? false : !(user.is_nsfw || user.show_nsfw)"
      class="d-flex align-items-center p-2 my-2"
      style="height: 30px"
      :style="changeColor ? `background: #ffc409 !important` : ''"
    >
      <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
      <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
        You must turn on “show mature content” and confirm you’re 18+ in
        <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
          <strong class="clickable-item-hov">Content Settings</strong>
        </router-link>
        to share mature content
      </p>
    </div>

    <div class="section mt-3">
      <div class="label mb-2 sub-title">Tags</div>
      <multiselect
        v-model="blabbing.tags"
        tag-placeholder="Select to create a tag"
        placeholder="Press Enter to Add Tags ↩"
        select-label=""
        deselect-label=""
        :show-no-options="false"
        :options="tagList"
        :multiple="true"
        :taggable="true"
        :loading="areTagsLoading"
        open-direction="bottom"
        :close-on-select="false"
        option-height="92"
        class="c-multiselect"
        @tag="addTag"
        @search-change="getTagsDebounced"
      ></multiselect>
    </div>
    <div class="section mt-3">
      <div class="sub-title mb-2">Category*</div>
      <ion-select v-model="blabbing.category" class="c-select" placeholder="What type of post is this?">
        <ion-select-option
          v-for="category in blabCategories"
          :key="`lst-cat-${category.value}`"
          :value="category.value"
        >
          {{ category.name }}
        </ion-select-option>
      </ion-select>
    </div>

    <div class="d-flex justify-content-center mt-3 pb-2">
      <ion-button
        :disabled="loading"
        v-if="get(user, 'is_email_verified', true)"
        class="post-btn"
        size="large"
        @click="onPost"
      >
        <ChLoading size="sm" v-if="loading" />
        <span v-else class="no-select header">{{ actionName }}</span>
      </ion-button>
      <VerificationButton :actionName="actionName" v-else />
    </div>
    <div v-if="edit" class="d-flex justify-content-center mt-5">
      <span class="delete-btn" @click="deleteWork">Delete</span>
    </div>

    <div class="mt-2 mb-3 d-flex justify-content-start align-items-center">
      <span class="no-select clickable-item-hov header" @click="toggleMoreSettings"
        >More Settings
        <i class="ti-angle-down open-more text-black" />
      </span>
    </div>
    <div class="ml-3 section pb-5" v-if="moreSettingsOpen">
      <div class="sub-title">Privacy</div>
      <div v-if="blabbing.is_nsfw && !user.is_nsfw && !user.show_nsfw" class="d-flex align-items-center mt-2">
        <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
        <p style="font-size: 10px; color: grey">
          You can not post mature content publicly until you confirm you're 18+ in
          <router-link to="/account/user-settings" target="_blank">
            <strong class="clickable-item-hov">Content Settings</strong>
          </router-link>
        </p>
      </div>
      <ion-radio-group v-model="blabbing.privacy" class="d-flex flex-column ml-2" mode="md">
        <div class="d-flex align-items-center mt-3">
          <ion-radio value="only_me" class="mr-1 clickable-item-hov" />
          <div class="d-flex flex-column">
            <div class="no-select bold clickable-item-hov" @click="setPrivacy('only_me')">Private</div>
            <div class="no-select msg">🔒 Only you can see your private posts.</div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-3">
          <ion-radio
            :disabled="blabbing.is_nsfw && !user.is_nsfw && !user.show_nsfw"
            class="mr-1 clickable-item-hov"
            value="public"
          />
          <div
            :style="blabbing.is_nsfw && !user.is_nsfw && !user.show_nsfw ? 'color: grey' : ''"
            class="d-flex flex-column"
          >
            <div
              class="no-select bold clickable-item-hov"
              @click="() => (blabbing.is_nsfw && !user.is_nsfw && !user.show_nsfw ? null : setPrivacy('public'))"
            >
              Public
            </div>
            <div class="no-select msg">Everyone can see your post.</div>
          </div>
        </div>
      </ion-radio-group>
      <div class="section mt-3">
        <div class="label mb-2 sub-title">Featured Characters</div>

        <div class="ml-2">
          <span class="clickable-item-hov" @click="openFeaturedModal"
            >Select Featured Characters <i class="ti-angle-down open-more text-black"
          /></span>

          <div class="d-flex align-items-center">
            <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
            <p style="font-size: 10px; color: grey">Only your characters can be featured in a post.</p>
          </div>

          <div class="flex flex-wrap align-items-center" v-if="get(blabbing, 'featured_characters.length')">
            <div
              v-for="(char, index) of blabbing.featured_characters.slice(0, charsDispCount)"
              :key="char.id"
              class="mx-2"
            >
              <img
                v-image
                class="featured-char-img mr-1"
                :src="resizeUpload(get(char, 'info.cropProfilePicture'), '80x80')"
              />
              <span>{{ truncateText(get(char, 'info.name', ''), 15) }}</span>
            </div>
            <small
              v-if="blabbing.featured_characters.length > charsDispCount"
              class="clickable-item-hov ml-2"
              style="font-weight: bold"
              @click="openFeaturedModal"
            >
              +{{ blabbing.featured_characters.length - charsDispCount }} more</small
            >
          </div>
        </div>
      </div>
      <div id="settings-bottom" />
    </div>
    <div class="mb-4" />

    <post-as-select-modal :is-open="openPostAsModal" @dismiss-modal="closePostAsModal" @item-selected="selectPostAs" />

    <PostFeaturedCharactersModal
      :is-open="isFeaturedModalOpen"
      :selectedChars="blabbing.featured_characters"
      @dismiss-modal="closeFeaturedModal"
      @save="updateFeaturedChars"
    />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { alertController } from '@ionic/vue';
import Multiselect from 'vue-multiselect';
import { Blab } from '@/shared/types/static-types';
import { toast } from '@/shared/native';
import constants from '@/shared/statics/constants';
import VerificationButton from '@/shared/components/VerificationButton.vue';
import PostAsSelectModal from '@/shared/modals/PostAsSelectModal.vue';
import { createBlab, getBlabDetails, updateBlab, deleteBlab } from '@/shared/actions/blabs';
import { resizeUpload } from '@/shared/utils/upload';
import { requestUploadImageUrl, sendImageToS3, sendImageUrlToBackend } from '@/shared/services/upload';
import { getTags } from '@/shared/actions/tags';
import { authStore } from '@/shared/pinia-store/auth';
import { mainStore } from '@/shared/pinia-store/main';
import { getUserCharacters } from '@/shared/actions/characters';
import ProfileRing from '@/shared/components/ProfileRing.vue';
import { warningOutline } from 'ionicons/icons';
import PostFeaturedCharactersModal from '@/shared/pages/posts/components/PostFeaturedCharactersModal.vue';
import { truncateText } from '@/shared/utils/string';

@Options({
  name: 'CreateBlab',
  components: { PostAsSelectModal, Multiselect, VerificationButton, ProfileRing, PostFeaturedCharactersModal },
})
export default class CreateBlab extends Vue {
  public openBlabAsModal = false;
  public pageWidth = 770;
  public loading = false;
  public moreSettingsOpen = false;
  isEmpty = isEmpty;
  resizeUpload = resizeUpload;

  public get tinyMCEKey() {
    const {
      public: { tinyMCEKey },
    } = useRuntimeConfig();
    return tinyMCEKey;
  }
  public warningOutline = warningOutline;
  public edit = false;
  public openPostAsModal = false;
  public selectPostAsData = {};
  images: any = [];
  postType = '';
  get = get;
  truncateText = truncateText;
  public isRandomPrompt = false;
  maxFileSizeMb = constants.uploadMaxFileSizeMB;
  public width = 534;
  public profileImg = '';

  public tagList: any = [];
  public areTagsLoading = false;
  public userCharacters: any = [];
  public changeColor = false;

  public isFeaturedModalOpen = false;

  public setPrivacy = (value: string) => {
    this.blabbing.privacy = value;
  };

  public get username() {
    const { user } = authStore();
    return '@' + user.value.username;
  }

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get lastPostAs() {
    const { lastPostAs } = authStore();
    return lastPostAs.value;
  }

  public get charsDispCount() {
    const { isLessThan570px } = useWindowSize();
    return isLessThan570px.value ? 2 : 3;
  }

  public get imageUploadUrl() {
    const {
      public: { hostUrl },
    } = useRuntimeConfig();
    return `${hostUrl}/posts/create`;
  }

  public get lastPostAsProfileImage() {
    if (this.isPostAsUser) return get(this.lastPostAs, 'item.profile_picture_url', '');
    return this.lastPostAs?.item?.info?.cropProfilePicture || this.lastPostAs?.item?.info?.profilePicture || '';
  }

  public get lastPostAsProfileRing() {
    if (this.isPostAsUser) return get(this.lastPostAs, 'item.customize_profile.profile_ring.image');
    return get(this.lastPostAs, 'item.profile_ring.image', '');
  }

  public get switchPostAsText() {
    return this.isPostAsUser ? 'Post as your character instead!' : `Switch who you're posting as.`;
  }

  public get lastPostAsName() {
    const { lastPostAsName } = authStore();
    return lastPostAsName.value;
  }

  public get isPostAsUser() {
    return !this.lastPostAs || this.lastPostAs.type === constants.commentedAsTypes.USER;
  }

  public blabbing: Blab = {
    title: '',
    description: '',
    category: '',
    location: '',
    id: '',
    character: '',
    is_nsfw: false,
    created: '',
    visuals: [],
    tags: [],
    featured_characters: [],
    privacy: 'public',
    // user:'';
  };

  public toggleMoreSettings() {
    this.moreSettingsOpen = !this.moreSettingsOpen;

    setTimeout(() => {
      const elem = document.querySelector('.layout-scroll-area');

      if (!elem) return;

      if (this.moreSettingsOpen && elem) {
        elem.scrollTo({ top: 9999, behavior: 'smooth' });
      }
    }, 150);
  }

  public openFeaturedModal(event) {
    event.preventDefault();
    event.stopPropagation();

    this.isFeaturedModalOpen = true;

    event.target.blur();
  }

  public closeFeaturedModal() {
    this.isFeaturedModalOpen = false;
  }

  public updateFeaturedChars(chars: any) {
    this.blabbing.featured_characters = chars;
  }

  public blabCategories = constants.blabCategories.sort((a, b) => a.name.localeCompare(b.name));

  public rerenderEditorKey = 0;

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  @Watch('dark')
  public rerenderTextarea() {
    this.rerenderEditorKey = this.rerenderEditorKey + 1;
  }

  blabPrompts = constants.prompts;
  choosenPrompts = '';
  isSaving = false;

  public get isValid() {
    return !!this.blabbing.title && !!this.blabbing.category;
  }

  public get actionName() {
    return this.edit ? 'Save' : 'Post';
  }

  public get titleAction() {
    return this.edit ? 'Edit' : 'Share';
  }

  public async deleteWork() {
    const alert = await alertController.create({
      cssClass: '',
      header: 'Are you sure?',
      message: `Please confirm that you want to delete this post.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'text-secondary',
          id: 'cancel-button',
        },
        {
          text: 'Confirm',
          id: 'confirm-button',
          role: 'ok',
          cssClass: 'text-secondary',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    const router = useRouter();
    if (role === 'ok') {
      await deleteBlab(this.blabbing.id);
      router.push({ name: 'posts-tabs' });
    }
  }

  public async fetchCharacters() {
    try {
      this.userCharacters = await getUserCharacters(this.user.id, null);
    } catch (_e) {}
  }

  public async onPost() {
    if (!this.isValid) {
      if (!this.blabbing.title && !this.blabbing.category) {
        return toast.show('Both title and category are required.', 'nonative', 'danger');
      }
      if (!this.blabbing.title) {
        return toast.show('Title is required.', 'nonative', 'danger');
      }
      if (!this.blabbing.category) {
        return toast.show('Category is required.', 'nonative', 'danger');
      }
    }
    this.blabbing.visuals = filter(this.blabbing.visuals, (item) => this.blabbing.description.includes(item));
    const router = useRouter();

    let resp;
    try {
      this.loading = true;
      const { lastPostAs } = authStore();
      const payload = {
        title: this.blabbing.title,
        description: this.blabbing.description,
        location: this.blabbing.location,
        is_nsfw: this.blabbing.is_nsfw,
        user: this.user.id,
        category: this.blabbing.category,
        visuals: this.blabbing.visuals,
        tags: this.blabbing.tags,
        character: lastPostAs.value.type === constants.commentedAsTypes.USER ? '' : lastPostAs.value.id,
        featured_characters: map(this.blabbing.featured_characters, (char) => char.id),
        privacy: this.blabbing.privacy,
      };
      if (this.edit) {
        resp = await updateBlab(this.blabbing.id, payload);
      } else {
        resp = await createBlab(payload);
      }
      await toast.show('Successfully saved.', 'nonative', 'success');
      router.push({ name: 'post-details', params: { id: resp.id } });
    } catch (_err) {
      await toast.show('Some error has occured. Please try again later.', 'nonative', 'danger');
    } finally {
      this.loading = false;
    }
  }

  public get randomPrompt() {
    const chosenNumber = Math.floor(Math.random() * this.blabPrompts.length);
    this.choosenPrompts = this.blabPrompts[chosenNumber];
    return this.choosenPrompts;
  }

  public randomClickPrompt() {
    const chosenNumber = Math.floor(Math.random() * this.blabPrompts.length);
    this.choosenPrompts = this.blabPrompts[chosenNumber];
  }

  public get postId() {
    const router = useRouter();
    return router.currentRoute.value.params.id;
  }

  public openPostAsList() {
    this.openPostAsModal = true;
  }

  public async getTags(search?: string) {
    this.areTagsLoading = true;

    if (!search) {
      this.tagList = [];
    } else {
      const resp = await getTags(1, 4, search);

      this.tagList = map(resp.results, (tag) => tag.name);
    }

    this.areTagsLoading = false;
  }
  public getTagsDebounced = debounce((...args: any[]) => this.getTags(...args), 500);

  public async image_uploader(blobInfo: any, progress: any) {
    const imageFile = blobInfo.blob();

    try {
      const threePostRequests = await requestUploadImageUrl();

      if (this.maxFileSizeMb && this.maxFileSizeMb * 1024 * 1024 < imageFile.size) {
        await toast.show(`File exceeds max size (${this.maxFileSizeMb}MB)`, 'nonative', 'danger');
      }

      if (!imageFile.type.startsWith('image/')) {
        throw new TypeError('File should be an image.');
      }
      const statusCode = await sendImageToS3(threePostRequests.l.url, threePostRequests.l.fields, imageFile);
      if (statusCode >= 400) throw new Error('Encountered an error while uploading image.');
      const imageData = await sendImageUrlToBackend(threePostRequests.l.fields.key, threePostRequests.l.url);

      if (!this.blabbing.visuals) {
        this.blabbing.visuals = [];
      } else {
        this.blabbing.visuals.push(resizeUpload(imageData.image, '1280w')!);
      }

      return resizeUpload(imageData.image, '1280w');
    } catch (e: any) {
      if (e instanceof TypeError) {
        // Do nothing
        await toast.show(e.message, 'nonative', 'danger');
      } else {
        // Do nothing
        const error: any = e;
        const body = 'Could not upload file.';
        const message = error ? error.message || body : body;
        await toast.show(message, 'nonative', 'danger');
      }
    }
  }

  public closePostAsModal() {
    this.openPostAsModal = false;
  }

  public addTag(newTag: any) {
    this.blabbing.tags.push(newTag);
    this.tagList.push(newTag);
  }

  public selectPostAs(data: { id: string; type: string; item: {} }) {
    const { updateLastPostAs } = authStore();
    updateLastPostAs({
      lastPostAs: data,
      lastPostAsName:
        data.type === constants.commentedAsTypes.USER
          ? get(data.item, 'username', 'Unknown')
          : get(data.item, 'info.name', 'Unknown'),
    });
  }

  public async created() {
    const { lastPostAs, lastPostAsName } = authStore();
    if (!lastPostAs.value || !lastPostAsName.value) {
      const { updateLastPostAs } = authStore();
      updateLastPostAs({
        lastPostAs: { id: this.user.id, type: constants.commentedAsTypes.USER, item: cloneDeep(this.user) },
        lastPostAsName: this.user.username,
      });
    }
  }

  public getWidth(src: any) {
    let width;
    const image = new Image();
    image.src = src;
    image.onload = function () {
      width = 532;
    };
    return width;
  }

  public async mounted() {
    this.fetchCharacters();
    if (this.postId) {
      const router = useRouter();
      const blab_details: Blab = await getBlabDetails(String(router.currentRoute.value.params.id));

      this.blabbing = blab_details;
      this.edit = true;
    }
  }
}
</script>

<style lang="sass" scoped>
.post-btn
  width: 100px
.title
  font-size: 26px
  font-weight: bold
  color: #214163
.sub-title
  font-size: 20px
  font-weight: bold
  font-family: Aleo
.delete-btn
  background-color: transparent
  color: gray
  cursor: pointer
  padding: 0 0.5rem 0.5rem 0.5rem

.one-character
  min-width: 40px
  width: 40px !important
  height: 40px !important
  border-radius: 20px
  border: solid gray 0.1px
  object-fit: cover

.delete-btn:hover
  text-decoration: underline

.c-select
  height: 42px !important
  border: 1px solid #CCC !important
  justify-content: space-between
  :deep
    input
      border: 0 !important

.dark .c-select
  border: 2px solid #4a288a !important

.c-multiselect
  :deep
    .multiselect__tags
      min-height: 42px !important
      border: 1px solid #CCC !important
      border-radius: 0.25rem !important
    .multiselect__placeholder
      padding-top: 3px

.dark .c-multiselect
  :deep
    .multiselect__tags
      border: 2px solid #4a288a !important
  .c-tiny-mce
    ::v-deep
      .tox
        border: 1px solid #CCC !important
        border-radius: 0.25rem !important
.dark .c-tiny-mce
  :deep
    .tox
      border: 2px solid #4a288a !important
.open-more
  position: relative
  display: inline-block
  top: 0.1rem
  cursor: pointer
  margin-left: 0.5rem
.posting-as
  font-size: 1.3rem
  font-weight: bold
  font-family: Aleo
  margin-left: 0rem
  margin-right: 0rem
  cursor: pointer
  :hover
    text-decoration: underline
.header
  font-size: 1.3rem
  font-weight: bold
  font-family: Aleo

.featured-char-img
  min-width: 30px
  width: 30px !important
  height: 30px !important
  border-radius: 15px
  border: solid gray 0.1px
  object-fit: cover
</style>
